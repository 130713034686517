import React from "react"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TagsIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const tags = data.allMarkdownRemark.group

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Tags" lang="id" />
      <header>
        <h1 itemProp="headline">Daftar Tag</h1>
      </header>
      <ol style={{ listStyle: `none` }} className="tags-list">
        {tags.map(item => {
          return (
            <li key={item.tag} className="item">
              <Link to={`/tags/${kebabCase(item.tag)}`} itemProp="url">
                <span itemProp="tag">
                  {item.tag} <small>({item.totalCount})</small>
                </span>
              </Link>
            </li>
          )
        })}
      </ol>
      <hr />
      <footer>
        <Bio />
      </footer>
    </Layout>
  )
}

export default TagsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
